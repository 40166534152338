import React, { useContext, useMemo } from 'react';
import { useOktaAuth, SecureRoute } from '@okta/okta-react';
import { AppContext } from '../App';
import Fade from 'react-reveal/Fade';
import Logo from '../assets/HF_logo.svg';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { oktaAuth } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();

  const { isSessionAllowedToShowData, isSessionVerifiedAsCurrent, userGroup } = useContext(AppContext);

  const allow = useMemo(
    () => !!isSessionAllowedToShowData && !!accessToken && !!isSessionVerifiedAsCurrent && !!userGroup,
    [isSessionAllowedToShowData, accessToken, isSessionVerifiedAsCurrent, userGroup]
  );

  return (
    <SecureRoute
      {...rest}
      render={(props) =>
        allow ? (
          <Component {...props} />
        ) : (
          <div className="redirect_wrap">
            <Fade bottom>
              <img src={Logo} alt="logo" />
            </Fade>
            <p>Checking user status...</p>
          </div>
        )
      }
    />
  );
};

export default ProtectedRoute;
