if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.trace = () => {};
  console.debug = () => {};
}

console.log('process.env', process.env);

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '';
const ISSUER = process.env.REACT_APP_ISSUER || '';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.REACT_APP_PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
const USE_INTERACTION_CODE = process.env.REACT_APP_USE_INTERACTION_CODE === 'true' || false;

const oktaAuthConfig = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: USE_INTERACTION_CODE,
    responseType: 'SPA',
    tokenManager: {
      autoRenew: true,
    },
  },
  app: {
    basename: BASENAME,
  },
};

// const oktaSignInConfig = {
//   baseUrl: 'https://okta.herdforce.com',
//   clientId: '0oa1hdmve3BFkQj3S5d7',
//   redirectUri: window.location.origin + '/login/callback',
//   authParams: {
//     // If your app is configured to use the Implicit flow
//     // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
//     // you will need to uncomment the below line
//     // pkce: false
//   },
//   features: {
//     // Used to enable registration feature on the widget.
//     // https://github.com/okta/okta-signin-widget#feature-flags
//     registration: true, // REQUIRED
//   },
//   logo: 'HF_logo.svg',
//   tokenManager: {
//     autoRenew: true,
//     secure: true,
//     storage: 'localStorage',
//   },
// };

export { oktaAuthConfig };
