import React, { useEffect, useState, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { AppContext } from '../../App';
import { useLocation, useHistory } from 'react-router-dom';
import { handleCustomerPortalHelper } from '../../helpers/stripe';
import useOnlineCheck from '../../helpers/useOnlineCheck';

function SessionGuard() {
  const location = useLocation();
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();
  const networkState = useOnlineCheck();

  const {
    userGroup,
    isSessionVerifiedAsCurrent,
    isSessionAllowedToShowData,
    setSessionAllowedToShowData,
    setSessionVerifiedAsCurrent,
    setLoadingWhileExtendingSession,
    invalidateUser,
    setLoginCountGuide,
  } = useContext(AppContext);

  const [ticker, setTicker] = useState(0);

  // backend is following refresh token time
  // frontend is following access token time
  // full token time from okta
  const [tokenTTL] = useState(3600); //1h in seconds

  const { online } = networkState;
  const skipPages = ['/cta', '/cta', '/success', '/session-check', '/'];

  const axiosClient = axios.create();
  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
  };

  const handleCustomerPortal = async () => {
    const token = oktaAuth.getAccessToken();
    if (!!token) {
      handleCustomerPortalHelper(token)
        .then((res) => {
          if (res.status === 200) {
            window.location.href = res.data.data.url;
          } else {
            if (res.status !== 200) {
              console.log('Error');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const extendSessionOnBackend = () => {
    // set session again to extend current session
    const token = oktaAuth.getAccessToken();
    localStorage.setItem('tokenUpdateBackupTime', new Date());
    localStorage.setItem('tokenUpdating', true);
    setLoadingWhileExtendingSession(true);
    console.log('!!!! session tokenUpdating date true ', JSON.stringify(new Date()));
    console.log('extendSessionOnBackend date token(bool)', new Date(), !!token);
    if (token) {
      axiosClient
        .post(
          `${process.env.REACT_APP_API_SERVER}/okta/session`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          localStorage.setItem('tokenUpdating', false);
          setSessionVerifiedAsCurrent(true);
          setLoginCountGuide(res.data?.guide_iteration ? res.data?.guide_iteration : 1);
          console.log('!!!! session tokenUpdating date false ', JSON.stringify(new Date()));
          if (res.data.group_name === '' || res.data.group_name === process.env.REACT_APP_DELETED) {
            history.push('/cta');
          } else if (res.data.group_name === process.env.REACT_APP_UNSUBCRIBERS) {
            handleCustomerPortal();
          } else {
            localStorage.setItem('checked', true);
            setSessionAllowedToShowData(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setSessionVerifiedAsCurrent(false);
          invalidateUser();
          console.log('!!!! session tokenUpdating date false ', JSON.stringify(new Date()));
        })
        .finally(() => {
          setLoadingWhileExtendingSession(false);
        });
    }
  };

  const checkSessionMethod = () => {
    const lastUpdate = localStorage.getItem('tokenUpdateTime');
    const tokenUpdating = localStorage.getItem('tokenUpdating');
    const firstDateInSeconds = new Date().getTime() / 1000;
    const secondDateInSeconds = new Date(lastUpdate ?? '').getTime() / 1000;
    const difference = Math.abs(firstDateInSeconds - secondDateInSeconds);

    console.log('-----------------');
    console.log('checkSessionMethod - localStorage.getItem(checked),', localStorage.getItem('checked'));
    console.log('-----------------');

    if (localStorage.getItem('checked') === 'true') {
      if (difference >= 5 && difference <= tokenTTL - 40 && tokenUpdating !== 'true') {
        extendSessionOnBackend();
      }
    } else {
      oktaAuth.token
        .getUserInfo()
        .then((info) => {
          localStorage.setItem('email', info.email);
          console.log('checkSessionMethod - email', info.email);
          const token = oktaAuth.getAccessToken();
          axiosClient
            .post(
              `${process.env.REACT_APP_API_SERVER}/okta/session-connected`,
              { email: info.email },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log('session-connected', res);
              if (res.status === 200) {
                extendSessionOnBackend();
              } else if (res.status === 401) {
                setSessionVerifiedAsCurrent(false);
                localStorage.removeItem('checked');
                window.location.href = '/session-check';
              }
            })
            .catch((err) => {
              console.error('session-connected error - ', err);
              localStorage.setItem('sessionCheckFail', new Date());
              localStorage.removeItem('checked');
              setSessionVerifiedAsCurrent(false);
              window.location.href = '/session-check';
            });
        })
        .catch((err) => console.error('err userinfo', err));
    }
  };

  const checkTokenMethod = () => {
    const token = oktaAuth.getAccessToken();
    const lastUpdate = localStorage.getItem('tokenUpdateTime');
    const tokenUpdating = localStorage.getItem('tokenUpdating');
    const firstDateInSeconds = new Date().getTime() / 1000;
    const secondDateInSeconds = new Date(lastUpdate ?? '').getTime() / 1000;
    const difference = Math.abs(firstDateInSeconds - secondDateInSeconds);

    console.log('----------------');
    console.log('*** checkTokenMethod /alive userGroup', userGroup);
    console.log('*** checkTokenMethod /alive token', token);
    console.log('*** checkTokenMethod /alive lastTokenUpdate', localStorage.getItem('tokenUpdateTime'));
    console.log('*** checkTokenMethod /alive difference', difference);
    console.log('*** checkTokenMethod /alive difference >= 30', difference >= 30);
    console.log('*** checkTokenMethod /alive tokenTTL', tokenTTL);
    console.log('*** checkTokenMethod /alive difference <= (tokenTTL - 40)', difference <= tokenTTL - 40);
    console.log('----------------');

    if (difference >= 30 && difference <= tokenTTL - 40 && tokenUpdating !== 'true') {
      if (!!token) {
        console.log(
          '-*-*-*-*-*-* IM CALLING /alice difference >= 30 && difference <= (tokenTTL - 40) && tokenUpdating !== "true" && !!token -*-*-*-*-',
          difference >= 30 && difference <= tokenTTL - 40 && tokenUpdating !== 'true' && !!token
        );
        axiosClient
          .get(`${process.env.REACT_APP_API_SERVER}/okta/alive`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async (res) => {
            if (res.status === 200) {
              console.log('*/*/*/*/*/*/*');
              console.log('*/*/*/*/*/*/*');
              console.log('*/*/*/*/*/*/* checkTokenMethod /alive success', new Date());
              console.log('*/*/*/*/*/*/*');
              console.log('*/*/*/*/*/*/*');
              localStorage.setItem('checked', true);
              setSessionAllowedToShowData(true);
              setSessionVerifiedAsCurrent(true);
              // refresh token
            } else if (res.status === 401) {
              localStorage.removeItem('checked');
              setSessionVerifiedAsCurrent(false);
              setSessionAllowedToShowData(false);
            }
          })
          .catch(async (err) => {
            console.error('checkTokenMethod /alive error', err);
            console.log('checkTokenMethod /alive error', err);
            // redundancy check
            if (err.message !== 'Network Error') {
              try {
                await oktaAuth.revokeRefreshToken();
                await oktaAuth.revokeAccessToken();
                localStorage.removeItem('checked');
                localStorage.removeItem('email');
                sessionStorage.removeItem('sessionGuide');
                setSessionAllowedToShowData(false);
                setSessionVerifiedAsCurrent(false);
              } catch (error) {
                console.log('failed to revoke tokens', err);
                localStorage.removeItem('checked');
                localStorage.removeItem('email');
                sessionStorage.removeItem('sessionGuide');
                setSessionAllowedToShowData(false);
                setSessionVerifiedAsCurrent(false);
              }
            }
          });
      } else {
        invalidateUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // used to move user from not skipPage rute
  useEffect(() => {
    const token = oktaAuth.getAccessToken();
    console.log('------------------------------------------');
    console.log('???? checkToken -  !!accessToken ', !!token);
    console.log('???? !!isSessionVerifiedAsCurrent ', !!isSessionVerifiedAsCurrent);
    console.log('???? !!!skipPages.includes(location.pathname) ', !!!skipPages.includes(location.pathname));
    console.log('???? !!online ', !!online);
    console.log('????  localStorage.getItem(tokenUpdating) ', localStorage.getItem('tokenUpdating') !== 'true');
    console.log('???? location.pathname ', location.pathname);
    console.log('------------------------------------------`');
    if (
      !!token &&
      !!isSessionVerifiedAsCurrent &&
      !!!skipPages.includes(location.pathname) &&
      !!userGroup &&
      !!online &&
      localStorage.getItem('tokenUpdating') !== 'true'
    ) {
      checkTokenMethod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionVerifiedAsCurrent, userGroup]);

  useEffect(() => {
    const token = oktaAuth.getAccessToken();
    console.log('checkSession - authState', authState);
    console.log(
      'checkSession - !!authState?.isAuthenticated && !!token && !!!skipPages. includes(location.pathname), location.pathname',
      !!authState?.isAuthenticated,
      !!token,
      !!!skipPages.includes(location.pathname),
      location.pathname,
      'all need to be true',
      JSON.stringify(new Date())
    );
    console.log('result', !!authState?.isAuthenticated && !!token && !!!skipPages.includes(location.pathname));

    if (!!authState?.isAuthenticated && !!token && !!!skipPages.includes(location.pathname)) {
      checkSessionMethod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    const tokenUpdating = localStorage.getItem('tokenUpdating');

    console.log('----------------------');
    console.log('!!!! checkTokenTicker - isSessionAllowedToShowData', isSessionAllowedToShowData);
    console.log('!!!! checkTokenTicker -userGroup', userGroup);
    console.log('!!!! checkTokenTicker -   (localStorage.getItem(checked) === true', localStorage.getItem('checked') === 'true');

    console.log('!!!! checkTokenTicker -online ', online);
    console.log('----------------------');

    // const tokenID = oktaAuth.getIdToken();

    if (!!oktaAuth && !!isSessionAllowedToShowData && !!userGroup && !!online && tokenUpdating !== 'true') {
      console.log('----------------------');
      console.log('!!!! checkTokenTicker - localStorage.getItem(tokenUpdateTime), ', localStorage.getItem('tokenUpdateTime'));
      console.log('----------------------');
      if (localStorage.getItem('tokenUpdateTime') === null) {
        localStorage.setItem('tokenUpdateTime', new Date());
      }
      checkTokenMethod();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker, isSessionAllowedToShowData]);

  useEffect(() => {
    console.log('----------------------', authState);
    console.log('&%^^%%^%^% authState -authState?.isAuthenticated', authState?.isAuthenticated);
    console.log('&%^^%%^%^% authState - isSessionAllowedToShowData', !!isSessionAllowedToShowData);
    console.log('&%^^%%^%^% authState - (localStorage.getItem(checked) !== true', localStorage.getItem('checked') !== 'true');
    console.log('----------------------');

    if (authState?.isAuthenticated && !!isSessionAllowedToShowData && localStorage.getItem('checked') !== 'true') {
      console.log('user isAuthenticated - false');
      setSessionAllowedToShowData(false);
      setSessionVerifiedAsCurrent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionAllowedToShowData, authState]);

  // 10s live data simulation
  useEffect(() => {
    const interval = setInterval(() => {
      setTicker((ticker) => ticker + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return <></>;
}

export default SessionGuard;
