import React, { useEffect, Suspense, useState, useMemo, createContext } from 'react';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuthConfig } from './config';
import axios from 'axios';

import OktaGuard from './components/Auth/OktaGuard';
import SessionGuard from './components/Auth/SessionGuard';
import useDisablePinchZoomEffect from './helpers/useDisablePinchZoomEffect';
import ProtectedRoute from './helpers/protectedRoute';
import logoutUser from './helpers/logoutUser';
const MacroPage = React.lazy(() => import('./pages/MacroPage'));
const SearchPage = React.lazy(() => import('./pages/SearchPage'));
const DualLineChartPage = React.lazy(() => import('./pages/DualLineChartPage'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const CTAPage = React.lazy(() => import('./pages/CTAPage'));
const Success = React.lazy(() => import('./pages/Success'));
const TOSPage = React.lazy(() => import('./pages/TOSPage'));
const PrivacyPage = React.lazy(() => import('./pages/PrivacyPage'));
const GuidePage = React.lazy(() => import('./pages/GuidePage'));
const TradeViewPage = React.lazy(() => import('./pages/TradeViewPage'));
const SessionCheckPage = React.lazy(() => import('./pages/SessionCheckPage'));
const ScreenerPage = React.lazy(() => import('./pages/ScreenerPage'));

const oktaAuth = new OktaAuth(oktaAuthConfig.oidc);

export const AppContext = createContext({ userGroup: '' });

const App = () => {
  const isBrowser = typeof window !== 'undefined';

  const axiosClient = axios.create();
  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
  };

  const history = useHistory();

  useDisablePinchZoomEffect();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log('restoreOriginalUri', originalUri);
    if (!!originalUri && originalUri !== '/success') {
      history.replace(toRelativeUrl(originalUri, window?.location?.origin));
    }
  };

  const customAuthHandler = (e) => {
    console.log('customAuthHandler', e);
    localStorage.removeItem('checked');
    localStorage.removeItem('email');
    sessionStorage.removeItem('sessionGuide');
    setSessionAllowedToShowData(false);
    setSessionVerifiedAsCurrent(false);
    // WE NEED TO INVALIDATE TOKEN on backend
    window.location.href = '/login';
  };

  const onAuthResume = async () => {
    console.log('onAuthResume');
    history.push('/login');
  };

  let vh = useMemo(() => (!!window ? window.innerHeight * 0.01 : 0), []);
  let vw = useMemo(() => (!!window ? window.innerWidth * 0.01 : 0), []);

  const [userGroup, setUserGroup] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [refreshTime, setRefreshTime] = useState('No refresh');
  const [isSessionVerifiedAsCurrent, setSessionVerifiedAsCurrent] = useState(false);
  const [isSessionAllowedToShowData, setSessionAllowedToShowData] = useState(localStorage.getItem('checked') === 'true' || false);
  const [initialRouteTracked, setInitialRouteTracked] = useState(false);
  const [loadingWhileExtendingSession, setLoadingWhileExtendingSession] = useState(localStorage.getItem('tokenUpdating') === 'true');
  const [lastGraphUpdateTime, setLastGraphUpdateTime] = useState('');

  const [loginCountGuide, setLoginCountGuide] = useState(0);

  console.log(loadingWhileExtendingSession, ' loadingWhileExtendingSession loadingWhileExtendingSession loadingWhileExtendingSession');

  function cookieCheck(version = '') {
    const savedValue = localStorage?.getItem('herdForceVersion');
    if (savedValue !== version) {
      if (typeof window !== 'undefined') {
        localStorage.setItem('herdForceVersion', version);
        window.location.replace(window.location.href);
      }
    }
  }

  useEffect(() => {
    cookieCheck(process.env.REACT_APP_VERSION);
    getLastModifedTime();
    console.log(`app version - ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);

    window.addEventListener('storage', (e) => {
      if (e.key === 'sessionCheckFail') {
        setSessionVerifiedAsCurrent(false);
        history.push('/session-check');
      } else if (e.key === 'updateStart') {
        setLoadingWhileExtendingSession(true);
      } else if (e.key === 'updateEnd') {
        setLoadingWhileExtendingSession(false);
      }
    });

    var hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.mozHidden !== 'undefined') {
      hidden = 'mozHidden';
      visibilityChange = 'mozvisibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    if (typeof document.addEventListener === 'undefined' || typeof document[hidden] === 'undefined') {
      // doesn't support event listeners :(
    } else {
      // Handle page visibility change
      document.addEventListener(
        visibilityChange,
        function () {
          if (document[hidden]) {
            console.log('*****////****/////****');
            console.log('*****////****/////****');
            console.log('WENT TO SLEEP');
            console.log('*****////****/////****');
            console.log('*****////****/////****');
          } else {
            console.log('*****////****/////****');
            console.log('*****////****/////****');
            console.log('WOKE UP');
            console.log('*****////****/////****');
            console.log('*****////****/////****');
          }
        },
        false
      );
    }

    const getDataInterval = setInterval(() => {
      getLastModifedTime();
    }, 30 * 1000);

    return () => {
      clearInterval(getDataInterval);
      window.removeEventListener('storage', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.warn('changed loadingWhileExtendingSession!', loadingWhileExtendingSession);
  }, [loadingWhileExtendingSession]);

  useEffect(() => {
    console.warn('changed loadingWhileExtendingSession!', loadingWhileExtendingSession);
  }, [loadingWhileExtendingSession]);

  useEffect(() => {
    console.warn('changed loadingWhileExtendingSession!', loadingWhileExtendingSession);
  }, [loadingWhileExtendingSession]);

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      let vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      return () => {
        window.removeEventListener('resize');
      };
    });
  }, [vh, vw]);

  if (isBrowser && !initialRouteTracked) {
    console.log('initialRouteTracked');
    localStorage.setItem('tokenUpdating', false);
    setInitialRouteTracked(true);
  }

  if (!initialRouteTracked && localStorage.getItem('okta-token-storage') !== null) {
    console.log('session verified');
    setSessionVerifiedAsCurrent(true);
  }

  const getLastModifedTime = () => {
    const accessToken = oktaAuth.getAccessToken();
    axiosClient
      .get(`${process.env.REACT_APP_API_SERVER}/storage/last-modified-time`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((res) => {
        const timeRes = new Date(res.data.time);
        const refreshTimeDate = new Date(refreshTime);

        if (refreshTime === 'No refresh') {
          setRefreshTime(res.data.time);
        } else if (timeRes !== refreshTimeDate) {
          setRefresh(true);
          setRefreshTime(res.data.time);
          setTimeout(() => {
            setRefresh(false);
          }, 5 * 1000);
        }
      });
  };

  const invalidateUser = (onlyOkta = false) => {
    const token = oktaAuth.getAccessToken();

    const logoutOkta = async () => {
      console.log('invalidateUserinvalidateUserinvalidateUserinvalidateUserinvalidateUser');

      try {
        await oktaAuth.revokeRefreshToken();
        await oktaAuth.revokeAccessToken();
        await oktaAuth.signOut();
        localStorage.removeItem('checked');
        localStorage.removeItem('email');
        sessionStorage.removeItem('sessionGuide');
        setSessionAllowedToShowData(false);
        setSessionVerifiedAsCurrent(false);
      } catch (error) {
        console.log('failed to revoke tokens', error);
        localStorage.removeItem('checked');
        localStorage.removeItem('email');
        sessionStorage.removeItem('sessionGuide');
        setSessionAllowedToShowData(false);
        setSessionVerifiedAsCurrent(false);
      }
      window.location.href = '/login';
    };

    if (onlyOkta) {
      logoutOkta();
    } else {
      logoutUser(token)
        .then((res) => {
          console.log('user backend call to logout - success', res);
        })
        .catch((err) => {
          console.error('user backend call to logout - error', err);
        })
        .finally(() => {
          logoutOkta();
        });
    }
  };

  return (
    <div>
      <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
        <Suspense fallback={<></>}>
          <AppContext.Provider
            value={{
              userGroup,
              setUserGroup,
              isSessionVerifiedAsCurrent,
              setSessionVerifiedAsCurrent,
              isSessionAllowedToShowData,
              setSessionAllowedToShowData,
              loadingWhileExtendingSession,
              setLoadingWhileExtendingSession,
              invalidateUser,
              lastGraphUpdateTime,
              setLastGraphUpdateTime,
              loginCountGuide,
              setLoginCountGuide,
            }}
          >
            <OktaGuard />
            <SessionGuard />

            <Switch>
              <Route path="/login/callback" render={(props) => <LoginCallback {...props} onAuthResume={onAuthResume} />} />

              <Route path="/login" render={() => <LoginPage />} />

              <Route exact path="/" render={() => <Redirect to="/bar-chart" />} />

              <Route exact path="/cta" component={CTAPage} />

              <SecureRoute exact path="/success" component={Success} />

              <ProtectedRoute exact path="/bar-chart" component={(props) => <HomePage {...props} onRefresh={refresh} />} />

              <ProtectedRoute exact path="/dual-line_chart/:id" component={(props) => <DualLineChartPage {...props} />} />

              <ProtectedRoute exact path="/macro" component={(props) => <MacroPage {...props} onRefresh={refresh} />} />

              <ProtectedRoute exact path="/search" component={(props) => <SearchPage {...props} />} />

              <ProtectedRoute exact path="/screener" component={(props) => <ScreenerPage {...props} onRefresh={refresh} />} />

              <ProtectedRoute exact path="/contact-us" component={(props) => <ContactUs {...props} />} />

              <ProtectedRoute exact path="/terms-of-services" component={(props) => <TOSPage {...props} />} />

              <ProtectedRoute exact path="/privacy" component={(props) => <PrivacyPage {...props} />} />

              <ProtectedRoute exact path="/guide" component={(props) => <GuidePage {...props} />} />

              <ProtectedRoute exact path="/tradingview/:id" component={(props) => <TradeViewPage {...props} />} />

              <SecureRoute exact path="/session-check" component={SessionCheckPage} />
              <Route component={NotFound} />
            </Switch>
          </AppContext.Provider>
        </Suspense>
      </Security>
    </div>
  );
};

export default App;
