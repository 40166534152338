import axios from 'axios';

/* const jwt = JSON.parse(localStorage.getItem('okta-token-storage')) || '';
const token = jwt && jwt.accessToken.value; */

export default function logoutUser(accessToken) {
  if (accessToken) {
    const axiosClient = axios.create();
    axiosClient.defaults.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
    };
    return axiosClient.delete(
      `${process.env.REACT_APP_API_SERVER}/okta/session`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } else {
    return Promise.reject('No token defined')
  }
}
