import React, { useEffect, useState, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { AppContext } from '../../App';
import { useLocation, useHistory } from 'react-router-dom';
import { handleCustomerPortalHelper } from '../../helpers/stripe';

import useOnlineCheck from '../../helpers/useOnlineCheck';

function OktaGuard() {
  const location = useLocation();
  const networkState = useOnlineCheck();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [ticker, setTicker] = useState(0);
  const { online } = networkState;

  const {
    setUserGroup,
    userGroup,
    isSessionAllowedToShowData,
    setSessionAllowedToShowData,
    setSessionVerifiedAsCurrent,
    setLoadingWhileExtendingSession,
    invalidateUser,
    loadingWhileExtendingSession,
    setLoginCountGuide
  } = useContext(AppContext);

  const axiosClient = axios.create();
  axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
  };

  const handleCustomerPortal = async () => {
    const token = oktaAuth.getAccessToken();
    if (!!token) {
      handleCustomerPortalHelper(token)
        .then((res) => {
          if (res.status === 200) {
            window.location.href = res.data.data.url;
          } else {
            if (res.status !== 200) {
              console.log('Error');
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const extendSessionOnBackend = () => {
    // set session again to extend current session
    const token = oktaAuth.getAccessToken();
    localStorage.setItem('tokenUpdateBackupTime', new Date());
    localStorage.setItem('tokenUpdating', true);
    setLoadingWhileExtendingSession(true);
    console.log('!!!! okta tokenUpdating date true ', JSON.stringify(new Date()));
    console.log('extendSessionOnBackend date token(bool)', new Date(), token);
    if (!!token) {
      axiosClient
        .post(
          `${process.env.REACT_APP_API_SERVER}/okta/session`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log('here checkSession', res.data.group_name);
          localStorage.setItem('tokenUpdating', false);
          setSessionVerifiedAsCurrent(true);
          console.log('!!!! tokenUpdating date false ', JSON.stringify(new Date()));
          if (res.data.group_name === '' || res.data.group_name === process.env.REACT_APP_DELETED) {
            history.push('/cta');
          } else if (res.data.group_name === process.env.REACT_APP_UNSUBCRIBERS) {
            handleCustomerPortal();
          } else {
            localStorage.setItem('checked', true);
            setSessionAllowedToShowData(true);
          }
        })
        .catch((err) => {
          console.log(err.message);
          localStorage.setItem('tokenUpdating', false);
          setSessionVerifiedAsCurrent(false);
          invalidateUser();
          console.log('!!!! tokenUpdating date false ', JSON.stringify(new Date()));
        })
        .finally(() => {
          setLoadingWhileExtendingSession(false);
          localStorage.setItem('updateEnd', new Date());
        });
    }
  };

  useEffect(() => {
    const token = oktaAuth.getAccessToken();
    console.log('------------------------------------------');
    console.log('checkGroup', location.pathname, !!token && token);
    console.log(
      'checkGroup  !!token, !!isSessionAllowedToShowData, !!userGroup',
      !!token,
      !!isSessionAllowedToShowData,
      !!userGroup,
      'all need to be true'
    );
    console.log('------------------------------------------');
    if (!!token && !!isSessionAllowedToShowData) {
      console.log('userGroup', userGroup, !!userGroup);
      if (!!userGroup) {
        return;
      } else {
        axiosClient
          .post(
            `${process.env.REACT_APP_API_SERVER}/okta/session`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (!!res.data.group_name) {
              // this needs to be better done
              console.log('res.data.group_name', res.data.group_name)
              console.log([process.env.REACT_APP_STARTER, process.env.REACT_APP_PROFESSIONAL].includes(res.data.group_name))
              if (
                [process.env.REACT_APP_STARTER, process.env.REACT_APP_PROFESSIONAL].includes(res.data.group_name)
              ) {
                setUserGroup(res.data.group_name);
                setLoginCountGuide(res.data?.guide_iteration ? res.data?.guide_iteration : 1);
              } else {
                history.push('/cta');
                setUserGroup('');
              }
            } else {
              history.push('/cta');
              setUserGroup('');
            }
          })
          .catch((err) => {
            console.error('checkGroup error', err);
            setSessionAllowedToShowData(false);
            setUserGroup('');
          });
      }
    } else {
      setUserGroup('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup, isSessionAllowedToShowData]);

  const subscribeTokenExpiryEventMethod = () => {
    console.log(' subscribeTokenExpiryEventMethod init');

    oktaAuth.tokenManager.on('expired', function (e) {
      console.log(' subscribeTokenExpiryEventMethod exipired', e);
    });

    oktaAuth.tokenManager.on('renewed', function (key, newToken, oldToken) {
      if (key === 'accessToken') {
        console.log('||| subscribeTokenExpiryEventMethod renewed', key);
        console.log('||| new-' + newToken[key]);
        console.log('||| old-' + oldToken[key]);
        console.log(
          '||| localStorage.getItem(okta-token-storage).accessToken-' + localStorage.getItem('okta-token-storage')?.accessToken?.accessToken
        );
        console.log('||| oktaAuth.getAccessToken()-' + oktaAuth.getAccessToken());
        console.log('|||tokenUpdating-' + localStorage.getItem('tokenUpdating'), JSON.stringify(new Date()));
        if (localStorage.getItem('tokenUpdating') !== 'true') {
          console.log('!!!!');
          console.log('!!!!');
          console.log('!!!! THIS TAB IS EXTENDING SESSION localStorage.getItem(tokenUpdating)', localStorage.getItem('tokenUpdateTime'));
          console.log('!!!!');
          console.log('!!!!');
          localStorage.setItem('tokenUpdateBackupTime', new Date());
          localStorage.setItem('tokenUpdateTime', new Date());
          localStorage.setItem('tokenUpdating', true);
          console.log('!!!! tokenUpdating date true ', JSON.stringify(new Date()));
          localStorage.setItem('updateStart', new Date());
          setLoadingWhileExtendingSession(true);
          setTimeout(() => {
            console.log('extendSessionOnBackend on renewed accessToken');
            extendSessionOnBackend();
          }, 5000);
        } else {
          console.log('????');
          console.log('???? THIS TAB IS NOT EXTENDING SESSION localStorage.getItem(tokenUpdating)', localStorage.getItem('tokenUpdateTime')); // this
          console.log('????');
          setLoadingWhileExtendingSession(true);
        }
      }
    });
  };

  // check if backend update token have been stoped
  useEffect(() => {
    /* disabled for testing */

    const token = oktaAuth.getAccessToken();
    console.log('------------------------------------------');
    console.log('///// checkToken -  !!accessToken ', !!token);
    console.log('///// checkTokenUpdateBackup - isSessionAllowedToShowData', isSessionAllowedToShowData);
    console.log('///// checkTokenUpdateBackup -userGroup', userGroup);
    console.log('///// checkTokenUpdateBackup -   (localStorage.getItem(checked) === true', localStorage.getItem('checked') === 'true');
    console.log('///// checkTokenUpdateBackup -online ', online);
    console.log('///// checkTokenUpdateBackup - localStorage.getItem(tokenUpdating), ', localStorage.getItem('tokenUpdating'));
    console.log('///// checkTokenUpdateBackup - new Date() ', new Date());
    console.log(
      !!token &&
        !!isSessionAllowedToShowData &&
        !!userGroup &&
        !!online &&
        !!loadingWhileExtendingSession &&
        localStorage.getItem('tokenUpdating') === 'true'
    );
    console.log('----------------------');
    if (
      !!token &&
      !!isSessionAllowedToShowData &&
      !!userGroup &&
      !!online &&
      !!loadingWhileExtendingSession &&
      localStorage.getItem('tokenUpdating') === 'true'
    ) {
      const lastUpdateBackup = localStorage.getItem('tokenUpdateBackupTime');
      const firstDateInSeconds = new Date().getTime() / 1000;
      const secondDateInSeconds = new Date(lastUpdateBackup ?? '').getTime() / 1000;
      const difference = Math.abs(firstDateInSeconds - secondDateInSeconds);
      // const tokenUpdating = localStorage.getItem('tokenUpdating');
      console.log('////////////////////////////////////////////////////');
      console.log('///// checkTokenUpdateBackupdifference, difference', difference, difference >= 5);
      // console.log('///// checkTokenUpdateBackupdifference tokenUpdating === true', tokenUpdating === 'true');
      if (difference >= 5) {
        console.log('!!!!');
        console.log('!!!!');
        console.log('!!!! THIS TAB IS EXTENDING SESSION AGAIN -> T - 1 SEC');
        console.log('!!!!');
        console.log('!!!!');
        localStorage.setItem('tokenUpdateBackupTime', new Date());
        localStorage.setItem('tokenUpdating', true);
        setTimeout(() => {
          console.log('-------- extendSessionOnBackend on backup mode');
          extendSessionOnBackend();
        }, 1000);
      }
    } else if (localStorage.getItem('tokenUpdating') !== 'true') {
      console.log('///***///***///setLoadingWhileExtendingSession', JSON.stringify(new Date()));
      console.log('///***///***///setLoadingWhileExtendingSession', localStorage.getItem('tokenUpdating'));
      setLoadingWhileExtendingSession(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  // 10s live data simulation
  useEffect(() => {
    const interval = setInterval(() => {
      setTicker((ticker) => ticker + 1);
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  // startup
  useEffect(() => {
    subscribeTokenExpiryEventMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default OktaGuard;
